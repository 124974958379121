import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const Quote = (props) => {

  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        height: { xs:'420px', lg: '732px' },
        // flexGrow: 1,
        background: '#E9AD21',
        paddingTop: { xs:'31px', sm: '60px', lg: '80px' },
        paddingBottom: { xs:'53px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '99px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'20px', sm: '26px', md: '30px', lg: '54px' },
          lineHeight: { xs:'24px', sm: '30px', md: '36px', lg: '60px' },
          letterSpacing: '-1.125px',
          color: '#FFFFFF',
          maxWidth: { xs:'300px', sm: '600px', md: '750px', lg: '1200px', xl: '1500px' },
          paddingBottom: '21px',
        }}
      >
        “The Job-Readiness Room provides a way to increase the quality of job applicants in several industries that are finding talent recruitment challenges while giving North County residents a way to signal to employers that they'd be excellent entry-level workers. This is a true win-win proposition.”
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'18px', md: '24px', lg: '35px' },
          lineHeight: { xs:'20px', md: '30px', lg: '40px' },
          letterSpacing: '-1.125px',
          color: '#000000',
          maxWidth: { xs:'220px', sm: '500px', lg: '900px', xl: '1500px' },
          paddingBottom: '0px',
        }}
      >
        – Erik Bruvold
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: { xs:'16px', md: '20px', lg: '30px' },
          lineHeight: { xs:'20px', lg: '50px' },
          letterSpacing: '-1.125px',
          color: '#000000',
          maxWidth: { xs:'300px', sm: '500px', lg: '900px', xl: '1400px' },
          paddingBottom: '41px',
        }}
      >
        CEO, San Diego North Economic Development Council
      </Typography>

    </Box>
  );
};

export default Quote;