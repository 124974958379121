import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const ContactUs = (props) => {

  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        height: { xs:'300px', md: '500px', lg: '732px' },
        // flexGrow: 1,
        background: '#1B7392',
        paddingTop: { xs:'31px', md: '90px', lg: '129px' },
        paddingBottom: { xs:'53px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '80px', xl: '100px' },
        paddingRight: { xs:'26px', lg: '99px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'35px', md: '45px', lg: '54px', xl: '65px' },
          lineHeight: { xs:'40px', md: '50px', lg: '60px', xl: '70px' },
          letterSpacing: '-1.125px',
          color: '#FFFFFF',
          maxWidth: { xs:'220px', sm: '600px', lg: '450px', xl: '600px' },
          paddingBottom: '21px',
        }}
      >
        Contact us
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'22px', md: '33px', lg: '40px', xl: '50px' },
          lineHeight: { xs:'30px', md: '40px', lg: '44px', xl: '56px' },
          letterSpacing: '-1.125px',
          color: '#FFFFFF',
          maxWidth: { xs:'350px', sm: '600px', md: '800px', lg: '450px', xl: '600px' },
          paddingBottom: '21px',
        }}
      >
        To find out more about the North County Job-Readiness Room, contact Sarah Zaner at <span style={{ color: '#E9AD21' }}>sarah@bendablelabs.com</span>.

      </Typography>


    </Box>
  );
};

export default ContactUs;