import React from 'react';

import Box from '@mui/material/Box';


const JrrLogo = (props) => {
  const {
    imageUrl = 'https://bendable.s3.us-west-1.amazonaws.com/jrr_logo.png',        // default logo
  } = props;

  return (
    <Box
      sx={{
        width: { xs: '116px', full: '232px'},
        height: { xs: '69px', full: '138px' },
        background: `url(${imageUrl}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      &nbsp;
    </Box>
  );
};

export default JrrLogo;