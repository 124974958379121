import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CtaButton = (props) => {
  const { 
    text, 
    color = "#F4615F", 
    backgroundColor = "#ffffff", 
    centerInParent = true,
    roundedEnds = false,
    onClick 
  } = props;

  return (
    <Box
      component="div"
      onClick={onClick}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: roundedEnds ? { xs:'50px', lg: '80px'} : { xs:'50px', lg: '100px'},
        width: { xs:'310px', lg: '437px', xl: '550px' },
        paddingTop: '20px',
        paddingBottom: '20px',
        paddingLeft: '20px', 
        paddingRight: '20px',
        background: backgroundColor,        // '#1B7392',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: roundedEnds ? '40px' : '24px',
        margin: centerInParent ? '0 auto' : 'inherit',
        cursor: 'pointer',
        boxShadow: 3,
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Sharp Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: { xs:'22px', lg: '30px'},
          lineHeight: '28px',
          color: color
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default CtaButton;