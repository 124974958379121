import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import JrrLogo from './JrrLogo';


const JobReadinessRoom = (props) => {
  const {
    height = '100%',
  } = props;

  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        height: height,
        // flexGrow: 1,
        background: '#ffffff',                                         // was: '#E9AD21',
        paddingTop: { xs:'31px', lg: '129px' },
        paddingBottom: { xs:'33px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '80px' },
      }}
    >
      <Stack direction={{ xs: 'row', md: 'row' }} sx={{ paddingBottom: '60px' }}>
        <JrrLogo />
        <Typography
          sx={{
            fontFamily: 'Sharp Sans',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: { xs:'26px', sm: '36px', lg: '36px' },
            lineHeight: { xs:'28px', sm: '28px', lg: '28px' },
            letterSpacing: '-1.125px',
            color: '#787879',
            paddingTop: '20px',
            paddingLeft: '20px',
          }}
        >
          NORTH COUNTY
        </Typography>
      </Stack>



      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'44px', md: '60px', lg: '65px' },
          lineHeight: { xs:'50px', lg: '70px' },
          letterSpacing: '-1.125px',
          color: '#38718F',
          maxWidth: { xs:'100%', lg: '550px', xl: '1200px' },
          paddingBottom: { xs:'21px', lg: '60px' },
        }}
      >
        Job-Readiness Room
      </Typography>

    </Box>
  );
};

export default JobReadinessRoom;