import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import JrrLogo from './JrrLogo';
import CtaButton from './CtaButton';


const LearnMore = (props) => {
  const {
    height = '100%',
  } = props;

  const handleLearnMoreClick = () => {
    window.open('https://bendable.s3.us-west-1.amazonaws.com/About%2Bthe%2BJob-Readiness%2BRoom%2BProgram-Carlsbad.pdf');
  };



  return (
    <Box
      sx={{
        opacity: 99.9,
        // display: 'flex',
        // flexDirection: 'column',
        width: '100%',
        height: height,
        // flexGrow: 1,
        background: '#ffffff',                                         // was: '#E9AD21',
        paddingTop: { xs:'40px', sm: '80px', lg: '60px' },
        paddingBottom: { xs:'30px', sm: '60px', lg: '156px' },
        paddingLeft: { xs:'26px', lg: '99px' },
        paddingRight: { xs:'26px', lg: '80px' },
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Cooper Std',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: { xs:'26px', md: '35px', lg: '45px' },
          lineHeight: { xs:'30px', md: '40px', lg: '50px' },
          letterSpacing: '-1.125px',
          color: '#000000',
          maxWidth: { xs:'100%', lg: '550px', xl: '1200px' },
          paddingBottom: { xs:'30px',  sm: '60px', lg: '60px' },
        }}
      >
        Turn 15 hours of learning into a guaranteed job interview with a local employer 
      </Typography>

      <CtaButton roundedEnds={true}  text="Learn more" onClick={handleLearnMoreClick} />

    </Box>
  );
};

export default LearnMore;