import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import Layout from '../components/layout';
import PrimaryAppBar from '../components/appBar/PrimaryAppBar';

import BendableHeroBG1 from '../components/shared/bendableHeroBG1';

import Footer from '../components/footer/Footer';
import WellBeInTouch from '../components/librariesPage/wellBeInTouch';
import QuestionsOrConcerns from '../components/librariesPage/questionsOrConcerns';
import BendableHeroPortraitBG from '../components/librariesPage/bendableHeroPortraitBG';
import StepToGuarantee from '../components/librariesPage/stepToGuarantee';
import ReserveYourSpotForm from '../components/librariesPage/reserveYourSpotForm';
import JobReadinessRoom from '../components/northcountyjrr/JobReadinessRoom';
import SignUpPane from '../components/northcountyjrr/SignUpPane';
import ContactUs from '../components/northcountyjrr/ContactUs';
import Quote from '../components/northcountyjrr/Quote';
import LearnMore from '../components/northcountyjrr/LearnMore';


const NorthCountyJRR = (props) => {
  const { location } = props;
  console.log('NorthCountyJRR location: ', location);

  const handleScheduleConversation = () => {
    console.log('libraries.handleScheduleConversation() called...');
    setTimeout(() => {
      const el = document.getElementById('contactFormWrapper');
      if (el) el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 250);
  };

  const handleDownloadBrochure = () => {
    console.log('libraries.handleDownloadBrochure() called...');

  };

  return (
    <Layout pageTitle="North County JRR">
      {/* <PrimaryAppBar instanceName="" backPath="/" />

      <Box id="appBarCompensation" sx={{
        height: { xs: '58px', lg: '90px' },
      }} /> */}

      {/* HERO ROW */}
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        // sx={{
        //   height: { xs: '350px', md: '500px', lg: '900px' },
        // }}
      >
        <JobReadinessRoom height={{ xs: '320px', lg: '550px' }} />
        <BendableHeroPortraitBG
          imageUrl="https://bendable.s3.us-west-1.amazonaws.com/Red+Apron+dude+2_JRR.jpg"
          height={{ xs: '250px', sm: '450px', md: '550px', lg: '550px' }}
        />

      </Stack>

      {/* FORM */}
      <SignUpPane />

      {/* Learn More row */}
      <Stack
        direction={{ xs: 'column', lg: 'row' }}
        sx={{
          padding: { xs: '10px', sm: '20px', md: '40px' },
        }}
      >
        <BendableHeroPortraitBG
          imageUrl="https://bendable.s3.us-west-1.amazonaws.com/Three+phones+2_JRR.jpg"
          height={{ xs: '250px', sm: '450px', md: '550px', lg: '500px', xl: '550px' }}
        />
        <LearnMore
          color="#F4615F"
          height={{ xs: '320px', sm: '320px', md: '320px', lg: '500px', xl: '550px' }}
        />

      </Stack>


      <Quote />

      {/* Contact us row */}
      <Grid
        container
        direction={{ xs: 'column', lg: 'row'}}
        sx={{
          width: '100%',
          height: { xs: '100%', md: '100%' },
          background: '#1B7392',
          // alignItems: 'center',
          // marginTop: { xs: '57px' , lg: '94px' }, 
        }}
      >
        <Grid 
          item 
          xs={12} 
          sm={6}
          sx={{ overflow: 'hidden' }}
        >
          <ContactUs />
        </Grid>

        <Grid 
          item 
          xs={12} 
          sm={6}
        >
          <BendableHeroPortraitBG imageUrl="https://bendable.s3.us-west-1.amazonaws.com/whatdoyouwanttolearn_sq.jpg" />
        </Grid>
      </Grid>

      {/* <Box id="spacer1" sx={{
        height: { xs: '20px', lg: '60px' },
      }} /> */}

      {/* <Footer /> */}
    </Layout>
  )
}

export default NorthCountyJRR;