import React, { useState, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import callApi from '../../services/apiService';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import SignUpPaneInputPane from './SignUpPaneInputPane';
// import ContactFormConfirmationPane from './contactFormConfirmationPane';
import { Typography } from '@mui/material';
import CtaButton from './CtaButton';

const SignUpPane = (props) => {
  const {
    backgroundColor = '#F4615F',
    showTopLine,          // controls whether or not the top line of text is shown
  } = props;    
  const [showSpinner, setShowSpinner] = useState(false);


  // const handleSubmitButtonClick = useCallback((data) => {
  //   console.log('contactForm.js.handleSubmitButtonClick() data: ', data);
  //   setShowSpinner(true);
  //   callApi('createSalesInquiry', null, data, (err, apiResponse) => {
  //     console.log('contactForm.handleSubmitButtonClick() createEmailSignup; err: ', err);
  //     console.log('contactForm.handleSubmitButtonClick() createEmailSignup; apiResopnse: ', apiResponse);
  //     let status = null;
  //     if (err) {
  //       status = 'error';
  //     } else if (apiResponse && apiResponse.data && apiResponse.data.status) {
  //       status = apiResponse.data.status;
  //     } else if (apiResponse && apiResponse.statusText && (apiResponse.statusText !== 'OK')) {
  //       status = 'error';
  //     }
  //     setShowSpinner(false);
  //     console.log('contactForm.handleSubmitButtonClick() about to set confirmationItem with status: ', status);
  //     setItems(confirmationItemFactory(status));
  //   });
  // }, []);


  // /**
  //  * Note that classNames="item" actually resolves to .item-enter, .item-enter-active, .item-exit, .item-exit-active, etc.
  //  */
  // const formItemFactory = () => {
  //   return (
  //     // <CSSTransition key={0} timeout={500} in={true} classNames="item" unmountOnExit>
  //       <Box
  //         sx={{
  //           height: '400px'
  //         }}
  //       >
  //         <SignUpPaneInputPane onSubmit={handleSubmitButtonClick} />
  //       </Box>
  //     // </CSSTransition>
  //   );
  // };

  // const confirmationItemFactory = (status) => {
  //   return (
  //     // <CSSTransition key={1} timeout={500} in={true} classNames="item" unmountOnExit>
  //       <Box
  //         sx={{
  //           height: '400px'
  //         }}
  //       >
  //         <ContactFormConfirmationPane status={status} />
  //       </Box>
  //     // </CSSTransition>
  //   );
  // };

  // const [items, setItems] = useState(formItemFactory());

  const handleJobSeekerClick = () => {
    window.open('https://forms.gle/qXjbzQiA2S8Q9Yjb9');
  };

  const handleEmployerClick = () => {
    window.open('https://forms.office.com/g/kfDDxBQLdC');
  };

  return (
    <Box         
      sx={{
        backgroundColor: backgroundColor,
      }}
    >
      <Stack
        direction='column'
        sx={{
          maxWidth: { xs:'100%', md: '800px', lg: '1100px', xl: '1500px' },
          margin: '0px auto',
          paddingTop: { xs:'20px', md: '60px', lg: '80px' },
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Cooper Std',
            fontStyle: 'normal',
            fontWeight: 900,
            fontSize: { xs:'30px', lg: '54px' },
            lineHeight: { xs:'40px', lg: '60px' },
            letterSpacing: '-1.125px',
            color: '#FFFFFF',
            textAlign: 'left',
            paddingLeft: { xs:'20px', lg: '0px' },
          }}
        >
          Sign up
        </Typography>

        <Box 
          component="header" 
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            marginBottom: { xs: '30px', md: '120px' }
          }}
        >
          <Box
            sx={{
              paddingTop: { xs: '30px', lg: '60px' }
            }}
          >
            <CtaButton text="Job seeker" onClick={handleJobSeekerClick} />
          </Box>

          <Box flexGrow={1} sx={{ height: '1px' }} />

          <Box
            sx={{
              paddingTop: { xs: '30px', lg: '60px' }
            }}
          >
            <CtaButton text="Employer" onClick={handleEmployerClick} />
          </Box>


        </Box>


      </Stack>
    </Box>
  )
};

export default SignUpPane;